/*
Bring in normalize.css styles
Source: https://create-react-app.dev/docs/adding-css-reset/
 */
@import-normalize;

/* JetBrains Mono */
@font-face {
  font-family: "JetBrains Mono";
  src: url(../fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url(../fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url(../fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url(../fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url(../fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf)
    format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Inter */
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-ExtraLight.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  src: url(../fonts/Inter/Inter-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

body {
  background: hsl(240 11% 95% / 1);
}

h1, h2, h3, h4 {
  color: #02021d;
}

h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 130%;
}

h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 130%;

}

h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
}

.image-gallery.fullscreen-modal {
  height: calc(100% + 1px) !important;
}
.image-gallery-slide-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 101px);
}
.image-gallery-slide {
  display: flex;
  justify-content: center;
  width: 100vw !important;
  height: calc(100vh - 110px) !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: calc(100vh - 110px) !important;
  width: auto;
  object-fit: unset;
}
.image-gallery-content .image-gallery-slide iframe {
  aspect-ratio: 16 / 9;
}
.image-gallery-swipe {
  width: 100%;
}

/* Thumbnails */
.image-gallery-thumbnails-wrapper {
  min-height: 101px;
  max-height: 101px;
}
.image-gallery-thumbnail {
  transition: none !important;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  max-height: 83px;
  line-height: 0;
  object-fit: cover;
}


/****************************************************
CSS needed for 
https://www.npmjs.com/package/json-view-for-react
****************************************************/
.jsonview-code {
  display: block;
  padding: 0.5rem;
  background-color: rgb(40, 44, 52);
  color: rgb(136, 198, 190);
  overflow-x: scroll;
  border-radius: 5px;
}

.jsonview-line { white-space: nowrap; }
.jsonview-hi-line { white-space: nowrap; background-color: rgb(80, 80, 95); }

.jsonview-str { color: rgb(121, 182, 242); text-wrap: wrap; }
.jsonview-num { color: rgb(255, 255, 200); }
.jsonview-bool { color: rgb(197, 165, 197); }
.jsonview-null { color: rgb(197, 165, 197); }
.jsonview-prop { color: rgb(250, 200, 99); }

.jsonview-ln {
  display: inline-block;
  padding-right: 1rem;
  color: rgb(178, 178, 178);
  text-align: right;
  user-select: none;
}